// COMMON :: START

// Specifying the offset and the background for the section itself
@mixin sectionBGOpt($offsetBottomVal, $imgName) {
	z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% + #{$offsetBottomVal});
	background-image: url(#{$imgName});
	background-repeat: no-repeat;
	background-position-x: center;
	background-size: cover;
}

// Overlap the previous block with the following
.body {
  //opacity: 0;

	> section {
      z-index: 2;
      position: relative;
	}
}

// Main section property: path & wrapper z-index for overlapping bg
.section {
  &__path {
    z-index: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1366px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    &-img {
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      will-change: transform;
      transform-origin: center;
      backface-visibility: hidden;

      &-wrapper {
        position: relative;
        will-change: transform;
        transform-origin: center;
        backface-visibility: hidden;
        transition: transform 2s cubic-bezier(0, 0, 0, 1) 0s;
      }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
  }
}


@keyframes c-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes c-slideInUp {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0);
  }
}

.animated {
  animation-fill-mode: both;
  animation-duration: 1s;
}



/* Illustration animation */
#illus1_rocket_outline, #illus1_rocket_path {
  animation: dash 50s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: -100%;
  }
}
/* Illustration animation :: end */

// COMMON :: END
