// SECTION team :: START

$c-team-card-bg: #f3f6fe;
$c-team-card-boxShadow: 0 26px 30px 0 rgba(0, 0, 0, 0.22);
$c-team-card-insetBoxShadow: inset 0 -7px 1px 0 #b5bdd5;
$c-team-card-name: #1b285c;
$c-team-card-position: #1b285c;
$c-team-card-btn-bg: $c-white-100;
$c-team-card-btn-bg-hover:  $c-white-100;
$c-team-card-btn-boxShadow: 0 14px 20px 0 rgba(0, 0, 0, 0.13);
$c-team-card-btn-insetBoxShadow: inset 0 -5px 1px 0 #d1cbe1;
$c-team-card-btn-color: $c-brand-100;

$c-team-hover-bg: $c-brand-100;

$w-pathImage-1: calc(1829px / 3);
$h-pathImage-1: calc(1719px / 3);

$w-pathImage-2: calc(861px / 3);
$h-pathImage-2: calc(887px / 3);

.team {
  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 320px);

    &:before {
      content: '';
      position: absolute;
      top: -60px;
      left: 0;
      width: 100%;
      height: 260px;
      background-image: url('../img/img-team-clipPath.svg');
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: cover;
      @include respondCustom('(min-width: 1921px)') {
        top: -100px;
        left: -200px;
        width: calc(100% + 400px);
        height: 350px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 199px;
      left: 0;
      width: 100%;
      height: calc(100% - 201px);
      background-color: $c-brand-170;
    }
  }

  &__path {
    &-img {
      &-1 {
        top: -242px;
        right: 0;
        width: $w-pathImage-1;
        height: $h-pathImage-1;
        filter: blur($filter-blur-image-13);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-1} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-1} / 1.75);
        }
        @include adaptive(767) {
          top: -102px;
          max-width: calc(#{$w-pathImage-1} / 2);
          max-height: calc(#{$h-pathImage-1} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-1} / 2.5);
        }
        @include adaptive(374) {
          top: -52px;
          max-width: calc(#{$w-pathImage-1} / 3);
          max-height: calc(#{$h-pathImage-1} / 3);
        }
      }

      &-2 {
        top: 141px;
        left: 20px;
        width: $w-pathImage-2;
        height: $h-pathImage-2;
        filter: blur($filter-blur-image-14);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-2} / 1.5);
        }
        @include adaptive(1023) {
          top: 101px;
          max-width: calc(#{$w-pathImage-2} / 1.5);
        }
        @include adaptive(767) {
          top: 131px;
          max-width: calc(#{$w-pathImage-2} / 2);
          max-height: calc(#{$h-pathImage-2} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-2} / 2.25);
        }
        @include adaptive(374) {
          left: 0;
          max-width: calc(#{$w-pathImage-2} / 2.75);
          max-height: calc(#{$h-pathImage-2} / 2.75);
        }
      }
    }
  }

  &__wrapper {
    padding-top: 330px;
    @include adaptive(767) {
      padding-top: 290px;
    }
  }

  &__title {
    position: relative;
    font-weight: 900;

    &-0 {
      left: 14px;
      padding-bottom: 65px;
      @include adaptive(767) {
        left: 0;
        padding-bottom: 35px;
      }
    }

    &-1 {
      left: -5px;
      padding-bottom: 28px;
      @include adaptive(767) {
        left: 0;
      }
    }
  }

  &__box {
    cursor: default;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 312px;
    padding: 20px;
    border-radius: 14px;
    background-color: $c-team-card-bg;
    box-shadow: $c-team-card-boxShadow, $c-team-card-insetBoxShadow;
    @include transition(transform);

    &:after {
      content: '';
      opacity: 0;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      background-color: $c-team-hover-bg;
      @include transition(opacity);
    }

    &:hover {
      transform: scale(1.1);
      @include transition(transform);

      &:after {
        opacity: 1;
        @include transition(opacity);
      }

      .team__box {
        &-image {
          background-color: #ffffff;
          @include transition(background-color);
        }

        &-name {
          color: #ffffff;
          transform: scale(1);
          @include transition(transform, color);
        }

        &-position {
          .link-content {
            opacity: 1;
            transform: scale(1);
            @include transition(opacity);
          }

          p {
            opacity: 0;
            transform: scale(1);
            @include transition(opacity);
          }
        }
      }
    }

    &-cover {
      position: relative;
      left: 11px;
      width: 100%;
      max-width: 1050px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 164px;
      @include adaptive(1279) {
        left: 0;
      }
      @include adaptive(767) {
        padding-bottom: 124px;
      }
      @include adaptive(575) {
        padding-bottom: 84px;
      }
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% + 20px);
      margin-left: -20px;
      @include adaptive(575) {
        flex-direction: column;
      }

      > div {
        width: 100%;
        max-width: calc(100% / 4);
        padding-left: 20px;
        padding-bottom: 30px;
        @include adaptive(1023) {
          max-width: calc(100% / 3);
        }
        @include adaptive(767) {
          max-width: calc(100% / 2);
        }
        @include adaptive(575) {
          max-width: 290px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &-image,
    &-name,
    &-position {
      z-index: 2;
      position: relative;
    }

    &-image {
      text-align: center;
      overflow: hidden;
      position: relative;
      height: 148px;
      margin-bottom: 20px;
      background-color: #939DB4;
      border-radius: 14px;
      @include transition(background-color);
      img {
        height: 100%;
      }
    }

    &-name {
      padding-bottom: 3px;
      font-size: 27px;
      font-weight: bold;
      color: $c-team-card-name;
      text-align: center;
      @include transition(transform, color);
      @include adaptive(1365) {
        font-size: 24px;
      }
      @include adaptive(1023) {
        font-size: 21px;
      }
      @include adaptive(767) {
        font-size: 19px;
      }
    }

    &-position {
      position: relative;

      .link-content {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 211px;
        height: 62px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 5px;
        border-radius: 31px;
        background-color: $c-team-card-btn-bg;
        box-shadow: $c-team-card-btn-boxShadow, $c-team-card-btn-insetBoxShadow;
        text-align: center;
        @include transition(opacity);
        @include adaptive(1023) {
          height: 52px;
        }
        @include adaptive(767) {
          top: 15px;
          max-width: 191px;
        }

        span {
          color: $c-team-card-btn-color;
          font-weight: bold;
          font-size: 19px;
          @include adaptive(1365) {
            font-size: 18px;
          }
          @include adaptive(1023) {
            font-size: 16px;
          }
          @include adaptive(767) {
            font-size: 14px;
          }
        }
      }

      &:hover {
        cursor: pointer;
        .link-content{
            background-color: $c-team-card-btn-bg-hover;
        }
      }

      p {
        font-size: 19px;
        font-weight: 300;
        color: $c-team-card-position;
        text-align: center;
        @include transition(opacity);
        @include adaptive(1365) {
          font-size: 18px;
        }
        @include adaptive(767) {
          font-size: 16px;
        }
      }
    }
  }

  &__btn {
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 340px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    background-color: $c-brand-100;
    border-radius: 62.5px;
    will-change: box-shadow;
    @include transition(box-shadow);
    @include adaptive(1365) {
      max-width: 280px;
      height: 80px;
    }
    @include adaptive(767) {
      max-width: 250px;
      height: 75px;
    }
    @include adaptive(575) {
      max-width: 220px;
      height: 70px;
    }

    &:hover {
      background-color: $c-brand-80;
    }

    span {
      position: relative;
      top: -2px;
      color: #fff;
      text-align: center;
      font-size: 31px;
      font-weight: 900;
      will-change: transform;
      transform-origin: center;
      @include transition(transform);
      @include adaptive(1365) {
        font-size: 25px;
      }
      @include adaptive(767) {
        font-size: 21px;
      }
      @include adaptive(575) {
        font-size: 17px;
      }
    }
  }

  &__image {
    position: relative;
    min-height: 700px;
    margin-left: auto;
    margin-right: auto;
    @include adaptive(1279) {
      min-height: 600px;
    }
    @include adaptive(1023) {
      min-height: 450px;
    }
    @include adaptive(767) {
      min-height: 360px;
    }
    @include adaptive(575) {
      min-height: 250px;
    }
    @include adaptive(424) {
      min-height: 250px;
    }
    @include adaptive(374) {
      min-height: 220px;
    }

    &-row {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 30px);
      margin-left: -15px;
      height: calc(100% + 400px);
      @include adaptive(1365) {
        height: calc(100% + 300px);
      }
      @include adaptive(575) {
        height: calc(100% + 250px);
      }
    }

    &-col {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: calc(100% + 400px);
      margin-left: -200px;
      @include adaptive(1365) {
        top: 50px;
        width: calc(100% + 300px);
        margin-left: -150px;
      }
      @include adaptive(1279) {
        top: 0;
      }
    }

    svg,
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
    }
  }
}

// SECTION team :: END
