// SECTION home :: START

.home {
  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 320px);
    background-color: $c-brand-170;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &-img {
      position: absolute;
      top: 75px;
      left: 45%;
      @include adaptive(575) {
        top: 35px;
        left: auto;
        right: 0;
      }
    }
  }

  &__wrapper {
    padding-top: 267px;
    padding-bottom: 56px;
    @include adaptive(767) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 475px;
      height: calc(100vh - 30px);
      padding-top: 217px;
    }
    @include adaptive(575) {
      padding-top: 137px;
    }
  }

  &__title {
    position: relative;
    left: -6px;
    display: flex;
    justify-content: center;
    padding-bottom: 23px;
    margin-left: auto;
    margin-right: auto;
    @include adaptive(1023) {
      max-width: calc(582px / 1.25);
    }
    @include adaptive(767) {
      left: 0;
      max-width: calc(582px / 1.5);
    }
    @include adaptive(575) {
      max-width: calc(528px / 1.75);
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__desc {
    width: 100%;
    max-width: 531px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 156px;
    font-weight: 400;
    font-size: 38px;
    color: #FFFFFF;
    text-align: center;
    line-height: line-height-convert(49, 38);
    letter-spacing: 0.25px;
    @include adaptive(1365) {
      font-size: 35px;
    }
    @include adaptive(1023) {
      font-size: 28px;
    }
    @include adaptive(767) {
      padding-bottom: 106px;
      font-size: 24px;
    }
    @include adaptive(575) {
      padding-bottom: 76px;
      font-size: 22px;
    }
  }

  &__scroll {
    text-align: center;

    p {
      padding-bottom: 16px;
      font-weight: 600;
      font-size: 24px;
      color:#ffffff
    }

    img {
      animation-fill-mode: both;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-name: animateScrollDownInd;
    }
  }
}

@keyframes animateScrollDownInd {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}

// SECTION home :: END
