///
/// ADDITIONALLY
/// ===============
$maxWidth: 100%;
$paddingLeft: 15px;
$paddingRight: $paddingLeft;

%grid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $paddingRight;
  padding-left: $paddingLeft;
}


///
/// MAIN
/// ===============
.c-grid {
  @extend %grid;
  @if($maxWidth) {
    max-width: $maxWidth;
  }


  &-fluid {
    @extend %grid;
    max-width: 100%;
  }
}


// for develop - pls del for production
.c-grid {
  @include respond(small) {
    max-width: $maxWidth;
  }
  @include respond(large) {
    max-width: $maxWidth;
  }
  @include respond(extra-large) {
    max-width: $maxWidth;
  }
  @include respond(medium) {
    max-width: $maxWidth;
  }
  @include respond(wide-small) {
    max-width: $maxWidth;
  }
}
