// FOOTER :: START

// FOOTER
// ========================
$c-footer-title: #fff;
$c-footer-address: #fff;
$c-footer-link: #fff;
$c-footer-link-icon: #fff;

$c-footer-linkCircle-gradientStart: #FFBA2A;
$c-footer-linkCircle-gradientEnd: #FFDC3E;
$c-footer-linkCircle-inner-gradientStart: #D97800;
$c-footer-linkCircle-inner-gradientEnd: #FFBF39;
$c-footer-linkCircle-boxShadow: #DB863D;

.footer {
  z-index: 3;
  position: relative;

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/img-footer-bg.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    min-height: 749px;
    padding-top: 291px;
    padding-bottom: 135px;
    @include adaptive(767) {
      min-height: 649px;
      padding-top: 241px;
      padding-bottom: 95px;
    }
    @include adaptive(575) {
      min-height: 549px;
    }
  }

  &__title,
  &__address {
    text-align: center;
    letter-spacing: 0;
  }

  &__title {
    padding-bottom: 10px;
    color: $c-footer-title;
    font-size: 42px;
    line-height: line-height-convert(40, 42);
    @include adaptive(1023) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 33px;
    }
    @include adaptive(575) {
      font-size: 30px;
    }
  }

  &__address {
    padding-bottom: 58px;
    color: $c-footer-address;
    font-size: 21px;
    line-height: line-height-convert(40, 21);
    @include adaptive(1023) {
      font-size: 18px;
    }
    @include adaptive(575) {
      font-size: 16px;
    }
  }

  &__social {
    &-list {
      display: flex;
      justify-content: center;
    }

    &-item {
      padding-left: 24px;
      padding-right: 24px;
      @include adaptive(575) {
        padding-left: 10px;
        padding-right: 10px;
      }
      @include adaptive(374) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &-link {
      > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 84px;
        border-radius: 50%;
        background-color: $c-brand-100;
        @include transition(box-shadow);
        @include adaptive(767) {
          width: 74px;
          height: 74px;
        }
        @include adaptive(575) {
          width: 64px;
          height: 64px;
        }
        @include adaptive(374) {
          width: 54px;
          height: 54px;
        }

        &:hover {
          background-color: $c-brand-80;
        }
      }
      .icon-font {
        z-index: 2;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform-origin: center;
        backface-visibility: hidden;
        will-change: transform;
        @include transition(transform 0.5s);

        &.icon-mail {
          width: 32px;
          height: 27px;
          background-image: url('../img/icon-mail@3x.png');
          @include adaptive(767) {
            width: calc(32px / 1.5);
          }
          @include adaptive(374) {
            width: calc(32px / 2);
          }
        }

        &.icon-angelco {
          width: 32px;
          height: 45px;
          background-image: url('../img/icon-angelco@3x.png');
          @include adaptive(767) {
            width: calc(32px / 1.5);
          }
          @include adaptive(374) {
            width: calc(32px / 2);
          }
        }

        &.icon-twitter {
          width: 42px;
          height: 35px;
          background-image: url('../img/x-logo.png');
          @include adaptive(767) {
            width: calc(42px / 1.5);
          }
          @include adaptive(374) {
            width: calc(42px / 2);
          }
        }

        &.icon-linkedin {
          width: 32px;
          height: 33px;
          background-image: url('../img/icon-linkedin@3x.png');
          @include adaptive(767) {
            width: calc(32px / 1.5);
          }
          @include adaptive(374) {
            width: calc(32px / 2);
          }
        }
      }

      p {
        padding-top: 15px;
        color: $c-footer-link;
        font-size: 21px;
        letter-spacing: 0;
        text-align: center;
        line-height: line-height-convert(40, 21);
        @include transition(opacity);
        @include adaptive(1023) {
          font-size: 18px;
        }
        @include adaptive(767) {
          font-size: 16px;
        }
        @include adaptive(575) {
          font-size: 14px;
        }
        @include adaptive(374) {
          font-size: 12px;
        }
      }
    }
  }
}

// FOOTER :: END
