.icon-icon-mail2 {
  width: 24px;
  height: 24px;
}

html,
body {
  width: 100%;
  min-height: 100vh;
}
html.is-hideScroll,
body.is-hideScroll {
  width: 100%;
  overflow: hidden !important;
}

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #0b1a36;
  line-height: 1.42857143;
  font-size: 14px;
  font-weight: normal;
  font-family: Nunito, Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body > .body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
}
body > .body--start {
  justify-content: flex-start;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
}

p,
h1, h2, h3, h4, h5, h6,
hr {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

a {
  outline: none;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
}

input[type=text] {
  appearance: none;
}

picture {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-grid-fluid, .c-grid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.c-grid {
  max-width: 100%;
}
.c-grid-fluid {
  max-width: 100%;
}

@media screen and (min-width: 576px) {
  .c-grid {
    max-width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .c-grid {
    max-width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .c-grid {
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .c-grid {
    max-width: 100%;
  }
}
@media screen and (min-width: 1366px) {
  .c-grid {
    max-width: 100%;
  }
}

.body > section {
  z-index: 2;
  position: relative;
}

.section__path {
  z-index: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1366px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.section__path-img {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  will-change: transform;
  transform-origin: center;
  backface-visibility: hidden;
}
.section__path-img-wrapper {
  position: relative;
  will-change: transform;
  transform-origin: center;
  backface-visibility: hidden;
  transition: transform 2s cubic-bezier(0, 0, 0, 1) 0s;
}
.section__wrapper {
  z-index: 2;
  position: relative;
}

@keyframes c-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes c-slideInUp {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0);
  }
}
.animated {
  animation-fill-mode: both;
  animation-duration: 1s;
}

/* Illustration animation */
#illus1_rocket_outline, #illus1_rocket_path {
  animation: dash 50s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: -100%;
  }
}
/* Illustration animation :: end */
.viewport-hide-js,
.viewport-hideCount-js {
  opacity: 0;
}

.viewport-show-js,
.viewport-showCount-js {
  opacity: 1;
}

.icon-svg {
  display: inline-block;
  fill: currentColor;
}

.c-title {
  font-size: 100px;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.08;
}
@media screen and (max-width: 1365px) {
  .c-title {
    font-size: 90px;
    line-height: 1.0888888889;
  }
}
@media screen and (max-width: 1023px) {
  .c-title {
    font-size: 75px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 767px) {
  .c-title {
    font-size: 60px;
  }
}
@media screen and (max-width: 575px) {
  .c-title {
    font-size: 45px;
  }
}
@media screen and (max-width: 374px) {
  .c-title {
    font-size: 40px;
  }
}

.c-text {
  font-size: 24px;
  font-weight: 300;
  color: rgba(201, 214, 255, 0.7);
  text-align: center;
  line-height: 1.5833333333;
  letter-spacing: 0.2px;
}
@media screen and (max-width: 1365px) {
  .c-text {
    font-size: 22px;
  }
}
@media screen and (max-width: 1023px) {
  .c-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .c-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 575px) {
  .c-text {
    font-size: 16px;
  }
}

.header {
  height: 70px;
  width: 100%;
  background-image: none;
  background: rgba(31, 20, 77, 0.5490196078);
  backdrop-filter: blur(8px);
  z-index: 9;
  transition: all 0.1s ease-in-out;
  position: fixed;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header__wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.header__wrapper > a {
  display: flex;
}
.header img.logo {
  margin-left: 25px;
}
.header img.icon-contact {
  vertical-align: middle;
}
.header img.icon-contact:hover {
  opacity: 0.9;
}
.header__right {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.header__right a {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 27px;
}
.header__right a:hover {
  color: rgba(255, 255, 255, 0.9019607843);
}

.footer {
  z-index: 3;
  position: relative;
}
.footer__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/img-footer-bg.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
.footer__wrapper {
  z-index: 2;
  position: relative;
  min-height: 749px;
  padding-top: 291px;
  padding-bottom: 135px;
}
@media screen and (max-width: 767px) {
  .footer__wrapper {
    min-height: 649px;
    padding-top: 241px;
    padding-bottom: 95px;
  }
}
@media screen and (max-width: 575px) {
  .footer__wrapper {
    min-height: 549px;
  }
}
.footer__title, .footer__address {
  text-align: center;
  letter-spacing: 0;
}
.footer__title {
  padding-bottom: 10px;
  color: #fff;
  font-size: 42px;
  line-height: 0.9523809524;
}
@media screen and (max-width: 1023px) {
  .footer__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  .footer__title {
    font-size: 33px;
  }
}
@media screen and (max-width: 575px) {
  .footer__title {
    font-size: 30px;
  }
}
.footer__address {
  padding-bottom: 58px;
  color: #fff;
  font-size: 21px;
  line-height: 1.9047619048;
}
@media screen and (max-width: 1023px) {
  .footer__address {
    font-size: 18px;
  }
}
@media screen and (max-width: 575px) {
  .footer__address {
    font-size: 16px;
  }
}
.footer__social-list {
  display: flex;
  justify-content: center;
}
.footer__social-item {
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 575px) {
  .footer__social-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 374px) {
  .footer__social-item {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.footer__social-link > div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background-color: #6842FF;
  transition: box-shadow 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .footer__social-link > div {
    width: 74px;
    height: 74px;
  }
}
@media screen and (max-width: 575px) {
  .footer__social-link > div {
    width: 64px;
    height: 64px;
  }
}
@media screen and (max-width: 374px) {
  .footer__social-link > div {
    width: 54px;
    height: 54px;
  }
}
.footer__social-link > div:hover {
  background-color: #8668FF;
}
.footer__social-link .icon-font {
  z-index: 2;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform-origin: center;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform 0.5s ease-in-out;
}
.footer__social-link .icon-font.icon-mail {
  width: 32px;
  height: 27px;
  background-image: url("../img/icon-mail@3x.png");
}
@media screen and (max-width: 767px) {
  .footer__social-link .icon-font.icon-mail {
    width: 21.3333333333px;
  }
}
@media screen and (max-width: 374px) {
  .footer__social-link .icon-font.icon-mail {
    width: 16px;
  }
}
.footer__social-link .icon-font.icon-angelco {
  width: 32px;
  height: 45px;
  background-image: url("../img/icon-angelco@3x.png");
}
@media screen and (max-width: 767px) {
  .footer__social-link .icon-font.icon-angelco {
    width: 21.3333333333px;
  }
}
@media screen and (max-width: 374px) {
  .footer__social-link .icon-font.icon-angelco {
    width: 16px;
  }
}
.footer__social-link .icon-font.icon-twitter {
  width: 42px;
  height: 35px;
  background-image: url("../img/x-logo.png");
}
@media screen and (max-width: 767px) {
  .footer__social-link .icon-font.icon-twitter {
    width: 28px;
  }
}
@media screen and (max-width: 374px) {
  .footer__social-link .icon-font.icon-twitter {
    width: 21px;
  }
}
.footer__social-link .icon-font.icon-linkedin {
  width: 32px;
  height: 33px;
  background-image: url("../img/icon-linkedin@3x.png");
}
@media screen and (max-width: 767px) {
  .footer__social-link .icon-font.icon-linkedin {
    width: 21.3333333333px;
  }
}
@media screen and (max-width: 374px) {
  .footer__social-link .icon-font.icon-linkedin {
    width: 16px;
  }
}
.footer__social-link p {
  padding-top: 15px;
  color: #fff;
  font-size: 21px;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.9047619048;
  transition: opacity 0.3s ease-in-out;
}
@media screen and (max-width: 1023px) {
  .footer__social-link p {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .footer__social-link p {
    font-size: 16px;
  }
}
@media screen and (max-width: 575px) {
  .footer__social-link p {
    font-size: 14px;
  }
}
@media screen and (max-width: 374px) {
  .footer__social-link p {
    font-size: 12px;
  }
}

.home__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 320px);
  background-color: #1F144D;
}
.home__bg svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home__bg-img {
  position: absolute;
  top: 75px;
  left: 45%;
}
@media screen and (max-width: 575px) {
  .home__bg-img {
    top: 35px;
    left: auto;
    right: 0;
  }
}
.home__wrapper {
  padding-top: 267px;
  padding-bottom: 56px;
}
@media screen and (max-width: 767px) {
  .home__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 475px;
    height: calc(100vh - 30px);
    padding-top: 217px;
  }
}
@media screen and (max-width: 575px) {
  .home__wrapper {
    padding-top: 137px;
  }
}
.home__title {
  position: relative;
  left: -6px;
  display: flex;
  justify-content: center;
  padding-bottom: 23px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1023px) {
  .home__title {
    max-width: 465.6px;
  }
}
@media screen and (max-width: 767px) {
  .home__title {
    left: 0;
    max-width: 388px;
  }
}
@media screen and (max-width: 575px) {
  .home__title {
    max-width: 301.7142857143px;
  }
}
.home__title img {
  display: block;
  max-width: 100%;
  height: auto;
}
.home__desc {
  width: 100%;
  max-width: 531px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 156px;
  font-weight: 400;
  font-size: 38px;
  color: #FFFFFF;
  text-align: center;
  line-height: 1.2894736842;
  letter-spacing: 0.25px;
}
@media screen and (max-width: 1365px) {
  .home__desc {
    font-size: 35px;
  }
}
@media screen and (max-width: 1023px) {
  .home__desc {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .home__desc {
    padding-bottom: 106px;
    font-size: 24px;
  }
}
@media screen and (max-width: 575px) {
  .home__desc {
    padding-bottom: 76px;
    font-size: 22px;
  }
}
.home__scroll {
  text-align: center;
}
.home__scroll p {
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
}
.home__scroll img {
  animation-fill-mode: both;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: animateScrollDownInd;
}

@keyframes animateScrollDownInd {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}
.we__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 320px);
}
.we__bg:before {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  width: calc(100% + 100px);
  height: 250px;
  background-image: url("../img/img-we-clipPath.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
@media screen and (min-width: 1921px) {
  .we__bg:before {
    top: -100px;
    left: -200px;
    width: calc(100% + 400px);
    height: 300px;
  }
}
.we__bg:after {
  content: "";
  position: absolute;
  top: 190px;
  left: 0;
  width: 100%;
  height: calc(100% - 190px);
  background-color: #342180;
}
.we__path-img-1 {
  top: -295px;
  left: -20px;
  width: 438px;
  height: 444px;
  filter: blur(0);
}
@media screen and (max-width: 1279px) {
  .we__path-img-1 {
    max-width: calc(438px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .we__path-img-1 {
    max-width: calc(438px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .we__path-img-1 {
    top: -105px;
    max-width: calc(438px / 2);
    max-height: calc(444px / 2);
  }
}
@media screen and (max-width: 575px) {
  .we__path-img-1 {
    max-width: calc(438px / 2.5);
    max-height: calc(444px / 2.5);
  }
}
.we__path-img-2 {
  top: -379px;
  right: 10px;
  width: 367.6666666667px;
  height: 264.3333333333px;
  filter: blur(1px);
}
@media screen and (max-width: 1279px) {
  .we__path-img-2 {
    max-width: calc(367.6666666667px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .we__path-img-2 {
    top: -279px;
    max-width: calc(367.6666666667px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .we__path-img-2 {
    top: -189px;
    max-width: calc(367.6666666667px / 2);
    max-height: calc(264.3333333333px / 2);
  }
}
@media screen and (max-width: 575px) {
  .we__path-img-2 {
    display: none !important;
  }
}
.we__path-img-3 {
  top: 309px;
  right: -47px;
  width: 492px;
  height: 456.3333333333px;
  filter: blur(0);
}
@media screen and (max-width: 1279px) {
  .we__path-img-3 {
    max-width: calc(492px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .we__path-img-3 {
    max-width: calc(492px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .we__path-img-3 {
    top: 189px;
    max-width: calc(492px / 2);
    max-height: calc(456.3333333333px / 2);
  }
}
@media screen and (max-width: 575px) {
  .we__path-img-3 {
    max-width: calc(492px / 2.5);
    max-height: calc(456.3333333333px / 2.5);
  }
}
.we__path-img-4 {
  top: 698px;
  left: 0;
  width: 605px;
  height: 541.6666666667px;
  filter: blur(0);
}
@media screen and (max-width: 1279px) {
  .we__path-img-4 {
    max-width: calc(605px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .we__path-img-4 {
    max-width: calc(605px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .we__path-img-4 {
    top: 428px;
    max-width: calc(605px / 2);
    max-height: calc(541.6666666667px / 2);
  }
}
@media screen and (max-width: 575px) {
  .we__path-img-4 {
    left: -100px;
    max-width: calc(605px / 2.5);
    max-height: calc(541.6666666667px / 2.5);
  }
}
.we__path-img-5 {
  top: 1220px;
  right: 50px;
  width: 558.3333333333px;
  height: 559px;
  filter: blur(11px);
}
@media screen and (max-width: 1279px) {
  .we__path-img-5 {
    max-width: calc(558.3333333333px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .we__path-img-5 {
    max-width: calc(558.3333333333px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .we__path-img-5 {
    top: 820px;
    max-width: calc(558.3333333333px / 2.25);
    max-height: calc(559px / 2);
  }
}
@media screen and (max-width: 575px) {
  .we__path-img-5 {
    max-width: calc(558.3333333333px / 2.5);
    max-height: calc(559px / 2.5);
  }
}
.we__wrapper {
  padding-top: 303px;
  padding-bottom: 93px;
}
@media screen and (max-width: 767px) {
  .we__wrapper {
    padding-top: 203px;
    padding-bottom: 43px;
  }
}
@media screen and (max-width: 575px) {
  .we__wrapper {
    padding-top: 153px;
  }
}
.we__box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.we__box-1 {
  margin-right: auto;
  padding-bottom: 165px;
}
@media screen and (max-width: 767px) {
  .we__box-1 {
    padding-left: 15px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 575px) {
  .we__box-1 {
    padding-bottom: 75px;
  }
}
@media screen and (max-width: 374px) {
  .we__box-1 {
    padding-left: 0;
  }
}
.we__box-2 {
  position: relative;
  left: -41px;
  margin-left: auto;
  padding-bottom: 194px;
}
@media screen and (max-width: 767px) {
  .we__box-2 {
    left: -21px;
    padding-bottom: 144px;
  }
}
@media screen and (max-width: 575px) {
  .we__box-2 {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 374px) {
  .we__box-2 {
    left: 0;
  }
}
.we__box-3 {
  position: relative;
  left: -13px;
  margin-right: auto;
  padding-bottom: 211px;
}
@media screen and (max-width: 1023px) {
  .we__box-3 {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .we__box-3 {
    padding-bottom: 161px;
  }
}
@media screen and (max-width: 575px) {
  .we__box-3 {
    padding-bottom: 121px;
  }
}
.we__box-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 765px;
  margin-left: auto;
  margin-right: auto;
}
.we__box-title, .we__box-num, .we__box-subtitle {
  text-align: center;
  letter-spacing: 0.3px;
}
.we__box-num, .we__box-subtitle {
  text-transform: uppercase;
}
.we__box-title {
  color: #fff;
  font-size: 32px;
  font-weight: 300;
}
@media screen and (max-width: 1365px) {
  .we__box-title {
    font-size: 30px;
  }
}
@media screen and (max-width: 1023px) {
  .we__box-title {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .we__box-title {
    font-size: 22px;
  }
}
.we__box-num {
  font-size: 0;
  backface-visibility: hidden;
  perspective: 0;
  perspective-origin: center;
  transform: translateZ(0);
}
.we__box-num,
.we__box-num span,
.we__box-num i {
  position: relative;
  top: 5px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 200px;
  font-size: 200px;
  font-weight: 900;
}
@media screen and (max-width: 1365px) {
  .we__box-num,
.we__box-num span,
.we__box-num i {
    line-height: 180px;
    font-size: 170px;
  }
}
@media screen and (max-width: 1023px) {
  .we__box-num,
.we__box-num span,
.we__box-num i {
    line-height: 160px;
    font-size: 150px;
  }
}
@media screen and (max-width: 767px) {
  .we__box-num,
.we__box-num span,
.we__box-num i {
    line-height: 110px;
    font-size: 100px;
  }
}
@media screen and (max-width: 575px) {
  .we__box-num,
.we__box-num span,
.we__box-num i {
    line-height: 90px;
    font-size: 85px;
  }
}
.we__box-subtitle {
  position: relative;
  top: -5px;
  color: #D2C6FF;
  font-size: 38px;
  font-weight: 700;
}
@media screen and (max-width: 1365px) {
  .we__box-subtitle {
    font-size: 35px;
  }
}
@media screen and (max-width: 1023px) {
  .we__box-subtitle {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .we__box-subtitle {
    font-size: 22px;
  }
}
@media screen and (max-width: 575px) {
  .we__box-subtitle {
    font-size: 20px;
  }
}
.we__box-subtitle a.link {
  text-decoration: none;
  color: #D2C6FF;
}

.mission__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 400px);
  background-image: url(../img/img-mission-bg.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  left: -100px;
  width: calc(100% + 200px);
}
.mission__path-img-1 {
  top: -158px;
  left: 159px;
  width: 431.6666666667px;
  height: 423px;
  filter: blur(0);
}
@media screen and (max-width: 1279px) {
  .mission__path-img-1 {
    top: -128px;
    max-width: calc(431.6666666667px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .mission__path-img-1 {
    left: 50px;
    max-width: calc(431.6666666667px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .mission__path-img-1 {
    top: -48px;
    max-width: calc(431.6666666667px / 2);
    max-height: calc(423px / 2);
  }
}
@media screen and (max-width: 575px) {
  .mission__path-img-1 {
    top: 0;
    left: 15px;
    max-width: calc(431.6666666667px / 2.5);
    max-height: calc(423px / 2.5);
  }
}
.mission__wrapper {
  padding-top: 233px;
}
@media screen and (max-width: 767px) {
  .mission__wrapper {
    padding-top: 183px;
  }
}
.mission__title {
  padding-bottom: 17px;
  font-weight: 900;
}
.mission__text {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-weight: 400;
  font-size: 24px;
}
.mission__illustration {
  position: relative;
  min-height: 759px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1279px) {
  .mission__illustration {
    min-height: 709px;
  }
}
@media screen and (max-width: 1023px) {
  .mission__illustration {
    min-height: 510px;
  }
}
@media screen and (max-width: 767px) {
  .mission__illustration {
    min-height: 370px;
  }
}
@media screen and (max-width: 575px) {
  .mission__illustration {
    min-height: 230px;
  }
}
@media screen and (max-width: 424px) {
  .mission__illustration {
    min-height: 190px;
  }
}
@media screen and (max-width: 374px) {
  .mission__illustration {
    min-height: 170px;
  }
}
.mission__illustration-row {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 30px);
  height: calc(100% + 350px);
  margin-left: -15px;
}
@media screen and (max-width: 1365px) {
  .mission__illustration-row {
    height: calc(100% + 300px);
  }
}
@media screen and (max-width: 1023px) {
  .mission__illustration-row {
    height: calc(100% + 250px);
  }
}
@media screen and (max-width: 575px) {
  .mission__illustration-row {
    height: calc(100% + 230px);
  }
}
.mission__illustration-col {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% + 400px);
  margin-left: -200px;
}
@media screen and (max-width: 1365px) {
  .mission__illustration-col {
    top: 50px;
    width: calc(100% + 300px);
    margin-left: -150px;
  }
}
@media screen and (max-width: 1023px) {
  .mission__illustration-col {
    top: 0;
    width: calc(100% + 250px);
    margin-left: -125px;
  }
}
@media screen and (max-width: 767px) {
  .mission__illustration-col {
    width: calc(100% + 200px);
    margin-left: -100px;
  }
}
@media screen and (max-width: 575px) {
  .mission__illustration-col {
    width: calc(100% + 150px);
    margin-left: -75px;
  }
}
.mission__illustration svg,
.mission__illustration img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.make__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 400px);
}
.make__bg:before {
  content: "";
  position: absolute;
  top: -30px;
  left: -50px;
  width: calc(100% + 100px);
  height: 350px;
  background-image: url("../img/img-make-clipPath.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
@media screen and (min-width: 1921px) {
  .make__bg:before {
    top: -80px;
    left: -200px;
    width: calc(100% + 400px);
    height: 400px;
  }
}
.make__bg:after {
  content: "";
  position: absolute;
  top: 300px;
  left: 0;
  width: 100%;
  height: calc(100% - 300px);
  background-color: #2A1A66;
}
.make__path-img-1 {
  top: -58px;
  right: 0;
  width: 596.3333333333px;
  height: 595.6666666667px;
  filter: blur(9px);
}
@media screen and (max-width: 1279px) {
  .make__path-img-1 {
    max-width: calc(596.3333333333px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .make__path-img-1 {
    max-width: calc(596.3333333333px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .make__path-img-1 {
    top: -28px;
    max-width: calc(596.3333333333px / 2);
    max-height: calc(595.6666666667px / 2);
  }
}
@media screen and (max-width: 575px) {
  .make__path-img-1 {
    max-width: calc(596.3333333333px / 2.5);
    max-height: calc(595.6666666667px / 2.5);
  }
}
@media screen and (max-width: 374px) {
  .make__path-img-1 {
    top: 30px;
    right: 0;
  }
}
.make__wrapper {
  padding-top: 341px;
  padding-bottom: 114px;
}
@media screen and (max-width: 767px) {
  .make__wrapper {
    padding-top: 191px;
    padding-bottom: 64px;
  }
}
.make__wrapper a {
  margin-top: 60px;
}
.make__title {
  padding-bottom: 19px;
  font-weight: 900;
}
.make__text {
  width: 100%;
  max-width: 839px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 57px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}
.make__box {
  text-align: center;
}
.make__box-1 .make__box-icon {
  display: flex;
  align-items: center;
}
.make__box-1 .make__box-icon img {
  position: relative;
  top: 4px;
  left: -1px;
}
@media screen and (max-width: 767px) {
  .make__box-1 .make__box-icon img {
    left: 0;
    top: 0;
  }
}
.make__box-2 .make__box-icon img {
  position: relative;
  left: 1px;
}
@media screen and (max-width: 767px) {
  .make__box-2 .make__box-icon img {
    left: 0;
  }
}
.make__box-3 .make__box-icon img {
  position: relative;
  left: -3px;
  top: 5px;
}
@media screen and (max-width: 767px) {
  .make__box-3 .make__box-icon img {
    left: 0;
    top: 0;
  }
}
.make__box-wrapper {
  position: relative;
  left: 13px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 941px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1023px) {
  .make__box-wrapper {
    left: 0;
  }
}
@media screen and (max-width: 575px) {
  .make__box-wrapper {
    flex-wrap: wrap;
  }
}
.make__box-wrapper > div {
  width: 100%;
  max-width: calc((100% - 140px) / 3);
}
@media screen and (max-width: 767px) {
  .make__box-wrapper > div {
    max-width: calc((100% - 90px) / 3);
  }
}
@media screen and (max-width: 575px) {
  .make__box-wrapper > div {
    max-width: 100%;
    padding-bottom: 30px;
  }
}
.make__box-icon {
  min-height: 202px;
}
.make__box-icon img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.make__box-title {
  padding-bottom: 13px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}
@media screen and (max-width: 1365px) {
  .make__box-title {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .make__box-title {
    font-size: 16px;
  }
}
.make__box-desc {
  color: #D2C6FF;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
}
@media screen and (max-width: 1365px) {
  .make__box-desc {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .make__box-desc {
    font-size: 16px;
  }
}
@media screen and (max-width: 575px) {
  .make__box-desc {
    width: 100%;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
}

.trusted__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 320px);
}
.trusted__bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: calc(100% + 100px);
  height: 350px;
  background-image: url("../img/img-team-clipPath.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
@media screen and (min-width: 1921px) {
  .trusted__bg:before {
    top: -50px;
    left: -200px;
    width: calc(100% + 400px);
    height: 400px;
  }
}
.trusted__bg:after {
  content: "";
  position: absolute;
  top: 300px;
  left: 0;
  width: 100%;
  height: calc(100% - 300px);
  background-color: #1F144D;
}
.trusted__grid {
  position: relative;
  left: 11px;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 164px;
}
@media screen and (max-width: 1279px) {
  .trusted__grid {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .trusted__grid {
    padding-bottom: 124px;
  }
}
@media screen and (max-width: 575px) {
  .trusted__grid {
    padding-bottom: 84px;
  }
}
.trusted__grid-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% + 10px);
  margin-left: -10px;
}
@media screen and (max-width: 1023px) {
  .trusted__grid-box {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 575px) {
  .trusted__grid-box {
    flex-direction: column;
  }
}
.trusted__grid-box > div {
  width: 100%;
  max-width: 25%;
  height: 150px;
  padding-left: 10px;
  padding-bottom: 10px;
}
@media screen and (max-width: 1023px) {
  .trusted__grid-box > div {
    max-width: 33.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .trusted__grid-box > div {
    max-width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .trusted__grid-box > div {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
}
.trusted__grid-item {
  border-radius: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
}
.trusted__grid-item img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.trusted__grid-item-1 {
  background-color: #fff;
}
.trusted__grid-item-2 {
  background-color: #C92F2E;
}
.trusted__grid-item-3 {
  background-color: #7536C6;
  padding: 30px;
}
.trusted__grid-item-4 {
  background-color: #06F;
  padding: 40px;
}
.trusted__grid-item-5 {
  background-color: #00B75D;
}
.trusted__grid-item-6 {
  background-color: #FEF;
  padding: 30px;
}
.trusted__grid-item-7 {
  background-color: #7D0505;
  padding: 30px;
}
.trusted__grid-item-8 {
  background-color: #005983;
  padding: 10px;
}
.trusted__grid-item-9 {
  background-color: #FF005B;
  padding: 35px;
}
.trusted__grid-item-10 {
  background-color: #FFD645;
  padding: 35px;
}
.trusted__grid-item-11 {
  background-color: #FFF;
}
.trusted__grid-item-12 {
  background-color: #0067DF;
}
.trusted__grid-item-13 {
  background-color: #FFF;
  padding: 10px;
}
.trusted__grid-item-14 {
  background-color: #445CC8;
}
.trusted__grid-item-15 {
  background-color: #0082A8;
  padding: 30px;
}
.trusted__grid-item-16 {
  background-color: #FFF;
}
.trusted__grid-item-17 {
  background-color: #FFF;
  padding: 30px;
}
.trusted__grid-item-18 {
  background-color: #27AFE5;
}
.trusted__grid-item-19 {
  background-color: #FFF;
  padding: 30px;
}
.trusted__grid-item-20 {
  background-color: #4380ED;
  padding: 25px;
}
.trusted__path-img-1 {
  top: -108px;
  left: 0;
  width: 498px;
  height: 464.3333333333px;
  filter: blur(10px);
}
@media screen and (max-width: 1279px) {
  .trusted__path-img-1 {
    max-width: calc(498px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .trusted__path-img-1 {
    max-width: calc(498px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .trusted__path-img-1 {
    top: -38px;
    max-width: calc(498px / 2);
    max-height: calc(464.3333333333px / 2);
  }
}
@media screen and (max-width: 575px) {
  .trusted__path-img-1 {
    max-width: calc(498px / 2.5);
    max-height: calc(464.3333333333px / 2.5);
  }
}
.trusted__path-img-2 {
  top: 144px;
  right: 78px;
  width: 209.3333333333px;
  height: 273.3333333333px;
  filter: blur(0);
}
@media screen and (max-width: 1279px) {
  .trusted__path-img-2 {
    max-width: calc(209.3333333333px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .trusted__path-img-2 {
    right: 18px;
    max-width: calc(209.3333333333px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .trusted__path-img-2 {
    top: 104px;
    right: 0;
    max-width: calc(209.3333333333px / 2);
    max-height: calc(273.3333333333px / 2);
  }
}
@media screen and (max-width: 575px) {
  .trusted__path-img-2 {
    max-width: calc(209.3333333333px / 2.5);
    max-height: calc(273.3333333333px / 2.5);
  }
}
.trusted__wrapper {
  padding-top: 308px;
  padding-bottom: 35px;
}
@media screen and (max-width: 767px) {
  .trusted__wrapper {
    padding-top: 258px;
    padding-bottom: 17px;
  }
}
.trusted__title {
  padding-bottom: 36px;
  font-weight: 900;
  text-transform: none;
}

.reasons__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 320px);
  background-image: url(../img/img-reasons-bg.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  top: -150px;
  left: -50px;
  width: calc(100% + 100px);
  height: calc(100% + 470px);
}
.reasons__path-img-1 {
  top: -52px;
  left: 50px;
  width: 492px;
  height: 515px;
  filter: blur(7px);
}
@media screen and (max-width: 1279px) {
  .reasons__path-img-1 {
    top: -22px;
    max-width: calc(492px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .reasons__path-img-1 {
    max-width: calc(492px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .reasons__path-img-1 {
    max-width: calc(492px / 2);
    max-height: calc(515px / 2);
  }
}
@media screen and (max-width: 575px) {
  .reasons__path-img-1 {
    left: 15px;
    max-width: calc(492px / 2.5);
    max-height: calc(515px / 2.5);
  }
}
@media screen and (max-width: 374px) {
  .reasons__path-img-1 {
    max-width: calc(492px / 2.75);
    max-height: calc(515px / 2.75);
  }
}
.reasons__wrapper {
  overflow: hidden;
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 452px 15px 204px;
}
@media screen and (max-width: 767px) {
  .reasons__wrapper {
    padding-top: 302px;
    padding-bottom: 104px;
  }
}
@media screen and (max-width: 575px) {
  .reasons__wrapper {
    padding-top: 252px;
  }
}
.reasons__title {
  padding-bottom: 7px;
  font-weight: 900;
}
.reasons__text {
  width: 100%;
  max-width: 745px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 133px;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .reasons__text {
    padding-bottom: 83px;
  }
}
@media screen and (max-width: 575px) {
  .reasons__text {
    padding-bottom: 40px;
  }
}
.reasons__carousel {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.reasons__carousel-btn {
  z-index: 10;
  position: absolute;
  top: calc(50% + 36px);
  transform: translateY(-50%);
  width: 103px;
  height: 116px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@media screen and (max-width: 1023px) {
  .reasons__carousel-btn {
    max-width: 56.5px;
  }
}
@media screen and (max-width: 575px) {
  .reasons__carousel-btn {
    display: none;
  }
}
.reasons__carousel-btn.swiper-button-disabled {
  opacity: 0.12;
  cursor: not-allowed;
}
.reasons__carousel-btn--prev {
  left: -15px;
  background-image: url("../img/prev@3x.png");
}
.reasons__carousel-btn--next {
  right: -15px;
  background-image: url("../img/next@3x.png");
}
.reasons__carousel-wrapper {
  position: relative;
}
.reasons__carousel-wrapper .swiper-container {
  width: 100%;
  max-width: 932px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 575px) {
  .reasons__carousel-wrapper .swiper-container {
    padding-bottom: 30px;
  }
}
.reasons__carousel-wrapper .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 50px;
}
.reasons__carousel-wrapper .swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 80px 70px;
}
@media screen and (max-width: 575px) {
  .reasons__carousel-wrapper .swiper-slide {
    padding: 0 30px 50px;
  }
}
.reasons__carousel-wrapper .swiper-slide:before, .reasons__carousel-wrapper .swiper-slide:after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.reasons__carousel-wrapper .swiper-slide:before {
  background-image: url("../img/img-reasons-bg-2.svg");
}
@media screen and (max-width: 575px) {
  .reasons__carousel-wrapper .swiper-slide:before {
    background-image: url("../img/img-reasons-bg-2--mobile.svg");
  }
}
@media screen and (max-width: 424px) {
  .reasons__carousel-wrapper .swiper-slide:before {
    background-image: url("../img/img-reasons-bg-2--extra-mobile.svg");
  }
}
.reasons__carousel-wrapper .swiper-slide:after {
  top: 20px;
  background-image: url("../img/img-reasons-bg-1.svg");
}
@media screen and (max-width: 575px) {
  .reasons__carousel-wrapper .swiper-slide:after {
    background-image: url("../img/img-reasons-bg-1--mobile.svg");
  }
}
@media screen and (max-width: 424px) {
  .reasons__carousel-wrapper .swiper-slide:after {
    background-image: url("../img/img-reasons-bg-1--extra-mobile.svg");
  }
}
.reasons__carousel-wrapper .swiper-slide-cover {
  z-index: 2;
  position: relative;
  top: -40px;
}
@media screen and (max-width: 1023px) {
  .reasons__carousel-wrapper .swiper-slide-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    top: 0;
  }
}
.reasons__carousel-wrapper .swiper-pagination {
  display: none;
  align-items: center;
  justify-content: center;
  bottom: 0;
}
@media screen and (max-width: 575px) {
  .reasons__carousel-wrapper .swiper-pagination {
    display: flex;
  }
}
.reasons__carousel-wrapper .swiper-pagination-bullet {
  opacity: 1;
  width: 15px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.35);
  margin-left: 3px;
  margin-right: 3px;
  transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.reasons__carousel-wrapper .swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 7px;
  background-color: #fff;
  transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.reasons__carousel-num {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  font-weight: 900;
  font-size: 200px;
  color: #fff;
  text-shadow: 0px 14px 54px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 767px) {
  .reasons__carousel-num {
    padding-bottom: 10px;
  }
}
.reasons__carousel-num img {
  position: relative;
  left: -3px;
}
@media screen and (max-width: 1023px) {
  .reasons__carousel-num img {
    left: 0;
    max-height: 130px;
  }
}
@media screen and (max-width: 767px) {
  .reasons__carousel-num img {
    max-height: 100px;
  }
}
@media screen and (max-width: 575px) {
  .reasons__carousel-num img {
    max-height: 85px;
  }
}
@media screen and (max-width: 374px) {
  .reasons__carousel-num img {
    max-height: 65px;
  }
}
.reasons__carousel-title, .reasons__carousel-desc {
  text-align: center;
}
.reasons__carousel-title {
  padding-bottom: 21px;
  font-size: 32px;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
}
@media screen and (max-width: 1365px) {
  .reasons__carousel-title {
    font-size: 30px;
  }
}
@media screen and (max-width: 1023px) {
  .reasons__carousel-title {
    padding-bottom: 15px;
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .reasons__carousel-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 575px) {
  .reasons__carousel-title {
    padding-bottom: 10px;
    font-size: 17px;
  }
}
@media screen and (max-width: 374px) {
  .reasons__carousel-title {
    padding-bottom: 5px;
    font-size: 15px;
  }
}
.reasons__carousel-desc {
  position: relative;
  left: 6px;
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  line-height: 1.6;
  letter-spacing: 0.1px;
}
@media screen and (max-width: 1365px) {
  .reasons__carousel-desc {
    font-size: 22px;
  }
}
@media screen and (max-width: 1023px) {
  .reasons__carousel-desc {
    left: 0;
    font-size: 19px;
  }
}
@media screen and (max-width: 767px) {
  .reasons__carousel-desc {
    font-size: 17px;
  }
}
@media screen and (max-width: 575px) {
  .reasons__carousel-desc {
    font-size: 14px;
  }
}
@media screen and (max-width: 374px) {
  .reasons__carousel-desc {
    font-size: 13px;
  }
}

.team__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 320px);
}
.team__bg:before {
  content: "";
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: 260px;
  background-image: url("../img/img-team-clipPath.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
@media screen and (min-width: 1921px) {
  .team__bg:before {
    top: -100px;
    left: -200px;
    width: calc(100% + 400px);
    height: 350px;
  }
}
.team__bg:after {
  content: "";
  position: absolute;
  top: 199px;
  left: 0;
  width: 100%;
  height: calc(100% - 201px);
  background-color: #1F144D;
}
.team__path-img-1 {
  top: -242px;
  right: 0;
  width: 609.6666666667px;
  height: 573px;
  filter: blur(17px);
}
@media screen and (max-width: 1279px) {
  .team__path-img-1 {
    max-width: calc(609.6666666667px / 1.25);
  }
}
@media screen and (max-width: 1023px) {
  .team__path-img-1 {
    max-width: calc(609.6666666667px / 1.75);
  }
}
@media screen and (max-width: 767px) {
  .team__path-img-1 {
    top: -102px;
    max-width: calc(609.6666666667px / 2);
    max-height: calc(573px / 2);
  }
}
@media screen and (max-width: 575px) {
  .team__path-img-1 {
    max-width: calc(609.6666666667px / 2.5);
  }
}
@media screen and (max-width: 374px) {
  .team__path-img-1 {
    top: -52px;
    max-width: calc(609.6666666667px / 3);
    max-height: calc(573px / 3);
  }
}
.team__path-img-2 {
  top: 141px;
  left: 20px;
  width: 287px;
  height: 295.6666666667px;
  filter: blur(0);
}
@media screen and (max-width: 1279px) {
  .team__path-img-2 {
    max-width: calc(287px / 1.5);
  }
}
@media screen and (max-width: 1023px) {
  .team__path-img-2 {
    top: 101px;
    max-width: calc(287px / 1.5);
  }
}
@media screen and (max-width: 767px) {
  .team__path-img-2 {
    top: 131px;
    max-width: calc(287px / 2);
    max-height: calc(295.6666666667px / 2);
  }
}
@media screen and (max-width: 575px) {
  .team__path-img-2 {
    max-width: calc(287px / 2.25);
  }
}
@media screen and (max-width: 374px) {
  .team__path-img-2 {
    left: 0;
    max-width: calc(287px / 2.75);
    max-height: calc(295.6666666667px / 2.75);
  }
}
.team__wrapper {
  padding-top: 330px;
}
@media screen and (max-width: 767px) {
  .team__wrapper {
    padding-top: 290px;
  }
}
.team__title {
  position: relative;
  font-weight: 900;
}
.team__title-0 {
  left: 14px;
  padding-bottom: 65px;
}
@media screen and (max-width: 767px) {
  .team__title-0 {
    left: 0;
    padding-bottom: 35px;
  }
}
.team__title-1 {
  left: -5px;
  padding-bottom: 28px;
}
@media screen and (max-width: 767px) {
  .team__title-1 {
    left: 0;
  }
}
.team__box {
  cursor: default;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 312px;
  padding: 20px;
  border-radius: 14px;
  background-color: #f3f6fe;
  box-shadow: 0 26px 30px 0 rgba(0, 0, 0, 0.22), inset 0 -7px 1px 0 #b5bdd5;
  transition: transform 0.3s ease-in-out;
}
.team__box:after {
  content: "";
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background-color: #6842FF;
  transition: opacity 0.3s ease-in-out;
}
.team__box:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.team__box:hover:after {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.team__box:hover .team__box-image {
  background-color: #ffffff;
  transition: background-color 0.3s ease-in-out;
}
.team__box:hover .team__box-name {
  color: #ffffff;
  transform: scale(1);
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}
.team__box:hover .team__box-position .link-content {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out;
}
.team__box:hover .team__box-position p {
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out;
}
.team__box-cover {
  position: relative;
  left: 11px;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 164px;
}
@media screen and (max-width: 1279px) {
  .team__box-cover {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .team__box-cover {
    padding-bottom: 124px;
  }
}
@media screen and (max-width: 575px) {
  .team__box-cover {
    padding-bottom: 84px;
  }
}
.team__box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% + 20px);
  margin-left: -20px;
}
@media screen and (max-width: 575px) {
  .team__box-wrapper {
    flex-direction: column;
  }
}
.team__box-wrapper > div {
  width: 100%;
  max-width: 25%;
  padding-left: 20px;
  padding-bottom: 30px;
}
@media screen and (max-width: 1023px) {
  .team__box-wrapper > div {
    max-width: 33.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .team__box-wrapper > div {
    max-width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .team__box-wrapper > div {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
}
.team__box-image, .team__box-name, .team__box-position {
  z-index: 2;
  position: relative;
}
.team__box-image {
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 148px;
  margin-bottom: 20px;
  background-color: #939DB4;
  border-radius: 14px;
  transition: background-color 0.3s ease-in-out;
}
.team__box-image img {
  height: 100%;
}
.team__box-name {
  padding-bottom: 3px;
  font-size: 27px;
  font-weight: bold;
  color: #1b285c;
  text-align: center;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}
@media screen and (max-width: 1365px) {
  .team__box-name {
    font-size: 24px;
  }
}
@media screen and (max-width: 1023px) {
  .team__box-name {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .team__box-name {
    font-size: 19px;
  }
}
.team__box-position {
  position: relative;
}
.team__box-position .link-content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 211px;
  height: 62px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
  border-radius: 31px;
  background-color: #F9FAFF;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.13), inset 0 -5px 1px 0 #d1cbe1;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}
@media screen and (max-width: 1023px) {
  .team__box-position .link-content {
    height: 52px;
  }
}
@media screen and (max-width: 767px) {
  .team__box-position .link-content {
    top: 15px;
    max-width: 191px;
  }
}
.team__box-position .link-content span {
  color: #6842FF;
  font-weight: bold;
  font-size: 19px;
}
@media screen and (max-width: 1365px) {
  .team__box-position .link-content span {
    font-size: 18px;
  }
}
@media screen and (max-width: 1023px) {
  .team__box-position .link-content span {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .team__box-position .link-content span {
    font-size: 14px;
  }
}
.team__box-position:hover {
  cursor: pointer;
}
.team__box-position:hover .link-content {
  background-color: #F9FAFF;
}
.team__box-position p {
  font-size: 19px;
  font-weight: 300;
  color: #1b285c;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}
@media screen and (max-width: 1365px) {
  .team__box-position p {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .team__box-position p {
    font-size: 16px;
  }
}
.team__btn {
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 340px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: #6842FF;
  border-radius: 62.5px;
  will-change: box-shadow;
  transition: box-shadow 0.3s ease-in-out;
}
@media screen and (max-width: 1365px) {
  .team__btn {
    max-width: 280px;
    height: 80px;
  }
}
@media screen and (max-width: 767px) {
  .team__btn {
    max-width: 250px;
    height: 75px;
  }
}
@media screen and (max-width: 575px) {
  .team__btn {
    max-width: 220px;
    height: 70px;
  }
}
.team__btn:hover {
  background-color: #8668FF;
}
.team__btn span {
  position: relative;
  top: -2px;
  color: #fff;
  text-align: center;
  font-size: 31px;
  font-weight: 900;
  will-change: transform;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}
@media screen and (max-width: 1365px) {
  .team__btn span {
    font-size: 25px;
  }
}
@media screen and (max-width: 767px) {
  .team__btn span {
    font-size: 21px;
  }
}
@media screen and (max-width: 575px) {
  .team__btn span {
    font-size: 17px;
  }
}
.team__image {
  position: relative;
  min-height: 700px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1279px) {
  .team__image {
    min-height: 600px;
  }
}
@media screen and (max-width: 1023px) {
  .team__image {
    min-height: 450px;
  }
}
@media screen and (max-width: 767px) {
  .team__image {
    min-height: 360px;
  }
}
@media screen and (max-width: 575px) {
  .team__image {
    min-height: 250px;
  }
}
@media screen and (max-width: 424px) {
  .team__image {
    min-height: 250px;
  }
}
@media screen and (max-width: 374px) {
  .team__image {
    min-height: 220px;
  }
}
.team__image-row {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 30px);
  margin-left: -15px;
  height: calc(100% + 400px);
}
@media screen and (max-width: 1365px) {
  .team__image-row {
    height: calc(100% + 300px);
  }
}
@media screen and (max-width: 575px) {
  .team__image-row {
    height: calc(100% + 250px);
  }
}
.team__image-col {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% + 400px);
  margin-left: -200px;
}
@media screen and (max-width: 1365px) {
  .team__image-col {
    top: 50px;
    width: calc(100% + 300px);
    margin-left: -150px;
  }
}
@media screen and (max-width: 1279px) {
  .team__image-col {
    top: 0;
  }
}
.team__image svg,
.team__image img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}