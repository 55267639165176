// SECTION mission :: START

$w-pathImage-1: calc(1295px / 3);
$h-pathImage-1: calc(1269px / 3);

.mission {
  &__bg {
    @include sectionBGOpt('400px', '../img/img-mission-bg.svg');
    left: -100px;
    width: calc(100% + 200px);
  }

  &__path {
    &-img {
      &-1 {
        top: -158px;
        left: 159px;
        width: $w-pathImage-1;
        height: $h-pathImage-1;
        filter: blur($filter-blur-image-6);
        @include adaptive(1279) {
          top: -128px;
          max-width: calc(#{$w-pathImage-1} / 1.25);
        }
        @include adaptive(1023) {
          left: 50px;
          max-width: calc(#{$w-pathImage-1} / 1.5);
        }
        @include adaptive(767) {
          top: -48px;
          max-width: calc(#{$w-pathImage-1} / 2);
          max-height: calc(#{$h-pathImage-1} / 2);
        }
        @include adaptive(575) {
          top: 0;
          left: 15px;
          max-width: calc(#{$w-pathImage-1} / 2.5);
          max-height: calc(#{$h-pathImage-1} / 2.5);
        }
      }
    }
  }

  &__wrapper {
    padding-top: 233px;
    @include adaptive(767) {
      padding-top: 183px;
    }
  }

  &__title {
    padding-bottom: 17px;
    font-weight: 900;
  }

  &__text {
    width: 100%;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    color:white;
    font-weight: 400;
    font-size: 24px;
  }

  &__illustration {
    position: relative;
    min-height: 759px;
    margin-left: auto;
    margin-right: auto;
    @include adaptive(1279) {
      min-height: 709px;
    }
    @include adaptive(1023) {
      min-height: 510px;
    }
    @include adaptive(767) {
      min-height: 370px;
    }
    @include adaptive(575) {
      min-height: 230px;
    }
    @include adaptive(424) {
      min-height: 190px;
    }
    @include adaptive(374) {
      min-height: 170px;
    }

    &-row {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 30px);
      height: calc(100% + 350px);
      margin-left: -15px;
      @include adaptive(1365) {
        height: calc(100% + 300px);
      }
      @include adaptive(1023) {
        height: calc(100% + 250px);
      }
      @include adaptive(575) {
        height: calc(100% + 230px);
      }
    }

    &-col {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: calc(100% + 400px);
      margin-left: -200px;
      @include adaptive(1365) {
        top: 50px;
        width: calc(100% + 300px);
        margin-left: -150px;
      }
      @include adaptive(1023) {
        top: 0;
        width: calc(100% + 250px);
        margin-left: -125px;
      }
      @include adaptive(767) {
        width: calc(100% + 200px);
        margin-left: -100px;
      }
      @include adaptive(575) {
        width: calc(100% + 150px);
        margin-left: -75px;
      }
    }

    svg,
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
    }
  }
}

// SECTION mission :: END
