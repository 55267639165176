// SECTION we :: START

$c-we-box-title: #fff;
$c-we-box-num: #fff;
$c-we-box-subtitle: $c-brand-30;

$w-pathImage-1: calc(1314px / 3);
$h-pathImage-1: calc(1332px / 3);

$w-pathImage-2: calc(1103px / 3);
$h-pathImage-2: calc(793px / 3);

$w-pathImage-3: calc(1476px / 3);
$h-pathImage-3: calc(1369px / 3);

$w-pathImage-4: calc(1815px / 3);
$h-pathImage-4: calc(1625px / 3);

$w-pathImage-5: calc(1675px / 3);
$h-pathImage-5: calc(1677px / 3);

.we {
  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 320px);

    &:before {
      content: "";
      position: absolute;
      top: -50px;
      left: -50px;
      width: calc(100% + 100px);
      height: 250px;
      background-image: url("../img/img-we-clipPath.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: cover;
      @include respondCustom("(min-width: 1921px)") {
        top: -100px;
        left: -200px;
        width: calc(100% + 400px);
        height: 300px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 190px;
      left: 0;
      width: 100%;
      height: calc(100% - 190px);
      background-color: $c-brand-150;
    }
  }

  &__path {
    &-img {
      &-1 {
        top: -295px;
        left: -20px;
        width: $w-pathImage-1;
        height: $h-pathImage-1;
        filter: blur($filter-blur-image-1);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-1} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-1} / 1.5);
        }
        @include adaptive(767) {
          top: -105px;
          max-width: calc(#{$w-pathImage-1} / 2);
          max-height: calc(#{$h-pathImage-1} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-1} / 2.5);
          max-height: calc(#{$h-pathImage-1} / 2.5);
        }
      }

      &-2 {
        top: -379px;
        right: 10px;
        width: $w-pathImage-2;
        height: $h-pathImage-2;
        filter: blur($filter-blur-image-2);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-2} / 1.25);
        }
        @include adaptive(1023) {
          top: -279px;
          max-width: calc(#{$w-pathImage-2} / 1.5);
        }
        @include adaptive(767) {
          top: -189px;
          max-width: calc(#{$w-pathImage-2} / 2);
          max-height: calc(#{$h-pathImage-2} / 2);
        }
        @include adaptive(575) {
          display: none !important;
        }
      }

      &-3 {
        top: 309px;
        right: -47px;
        width: $w-pathImage-3;
        height: $h-pathImage-3;
        filter: blur($filter-blur-image-3);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-3} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-3} / 1.5);
        }
        @include adaptive(767) {
          top: 189px;
          max-width: calc(#{$w-pathImage-3} / 2);
          max-height: calc(#{$h-pathImage-3} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-3} / 2.5);
          max-height: calc(#{$h-pathImage-3} / 2.5);
        }
      }

      &-4 {
        top: 698px;
        left: 0;
        width: $w-pathImage-4;
        height: $h-pathImage-4;
        filter: blur($filter-blur-image-4);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-4} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-4} / 1.5);
        }
        @include adaptive(767) {
          top: 428px;
          max-width: calc(#{$w-pathImage-4} / 2);
          max-height: calc(#{$h-pathImage-4} / 2);
        }
        @include adaptive(575) {
          left: -100px;
          max-width: calc(#{$w-pathImage-4} / 2.5);
          max-height: calc(#{$h-pathImage-4} / 2.5);
        }
      }

      &-5 {
        top: 1220px;
        right: 50px;
        width: $w-pathImage-5;
        height: $h-pathImage-5;
        filter: blur($filter-blur-image-5);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-5} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-5} / 1.5);
        }
        @include adaptive(767) {
          top: 820px;
          max-width: calc(#{$w-pathImage-5} / 2.25);
          max-height: calc(#{$h-pathImage-5} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-5} / 2.5);
          max-height: calc(#{$h-pathImage-5} / 2.5);
        }
      }
    }
  }

  &__wrapper {
    padding-top: 303px;
    padding-bottom: 93px;
    @include adaptive(767) {
      padding-top: 203px;
      padding-bottom: 43px;
    }
    @include adaptive(575) {
      padding-top: 153px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-1 {
      margin-right: auto;
      padding-bottom: 165px;
      @include adaptive(767) {
        padding-left: 15px;
        padding-bottom: 125px;
      }
      @include adaptive(575) {
        padding-bottom: 75px;
      }
      @include adaptive(374) {
        padding-left: 0;
      }

      .we__box-num {
        span {
          //min-width: 340px;
        }
      }
    }

    &-2 {
      position: relative;
      left: -41px;
      margin-left: auto;
      padding-bottom: 194px;
      @include adaptive(767) {
        left: -21px;
        padding-bottom: 144px;
      }
      @include adaptive(575) {
        padding-bottom: 100px;
      }
      @include adaptive(374) {
        left: 0;
      }

      .we__box-num {
        span {
          //min-width: 210px;
        }
      }
    }

    &-3 {
      position: relative;
      left: -13px;
      margin-right: auto;
      padding-bottom: 211px;
      @include adaptive(1023) {
        left: 0;
      }
      @include adaptive(767) {
        padding-bottom: 161px;
      }
      @include adaptive(575) {
        padding-bottom: 121px;
      }

      .we__box-num {
        span {
          //min-width: 340px;
        }
      }
    }

    &-4 {
      .we__box-num {
        span {
          //min-width: 350px;
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 765px;
      margin-left: auto;
      margin-right: auto;
    }

    &-title,
    &-num,
    &-subtitle {
      text-align: center;
      letter-spacing: 0.3px;
    }

    &-num,
    &-subtitle {
      text-transform: uppercase;
    }

    &-title {
      color: $c-we-box-title;
      font-size: 32px;
      font-weight: 300;
      @include adaptive(1365) {
        font-size: 30px;
      }
      @include adaptive(1023) {
        font-size: 28px;
      }
      @include adaptive(767) {
        font-size: 22px;
      }
    }

    &-num {
      font-size: 0;
      backface-visibility: hidden;
      perspective: 0;
      perspective-origin: center;
      transform: translateZ(0);

      &,
      span,
      i {
        position: relative;
        top: 5px;
        display: inline-block;
        vertical-align: middle;
        color: $c-we-box-num;
        line-height: 200px;
        font-size: 200px;
        font-weight: 900;
        @include adaptive(1365) {
          line-height: 180px;
          font-size: 170px;
        }
        @include adaptive(1023) {
          line-height: 160px;
          font-size: 150px;
        }
        @include adaptive(767) {
          line-height: 110px;
          font-size: 100px;
        }
        @include adaptive(575) {
          line-height: 90px;
          font-size: 85px;
        }
      }
    }

    &-subtitle {
      position: relative;
      top: -5px;
      color: $c-we-box-subtitle;
      font-size: 38px;
      font-weight: 700;
      @include adaptive(1365) {
        font-size: 35px;
      }
      @include adaptive(1023) {
        font-size: 28px;
      }
      @include adaptive(767) {
        font-size: 22px;
      }
      @include adaptive(575) {
        font-size: 20px;
      }
      a.link {
        text-decoration: none;
        color: $c-we-box-subtitle;
      }
    }
  }
}

// SECTION we :: END
