// SECTION make :: START
$w-pathImage-1: calc(1789px / 3);
$h-pathImage-1: calc(1787px / 3);

.make {
  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 400px);

    &:before {
      content: '';
      position: absolute;
      top: -30px;
      left: -50px;
      width: calc(100% + 100px);
      height: 350px;
      background-image: url('../img/img-make-clipPath.svg');
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: cover;
      @include respondCustom('(min-width: 1921px)') {
        top: -80px;
        left: -200px;
        width: calc(100% + 400px);
        height: 400px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 300px;
      left: 0;
      width: 100%;
      height: calc(100% - 300px);
      background-color: $c-brand-160;
    }
  }

  &__path {
    &-img {
      &-1 {
        top: -58px;
        right: 0;
        width: $w-pathImage-1;
        height: $h-pathImage-1;
        filter: blur($filter-blur-image-7);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-1} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-1} / 1.5);
        }
        @include adaptive(767) {
          top: -28px;
          max-width: calc(#{$w-pathImage-1} / 2);
          max-height: calc(#{$h-pathImage-1} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-1} / 2.5);
          max-height: calc(#{$h-pathImage-1} / 2.5);
        }
        @include adaptive(374) {
          top: 30px;
          right: 0;
        }
      }
    }
  }

  &__wrapper {
    padding-top: 341px;
    padding-bottom: 114px;
    @include adaptive(767) {
      padding-top: 191px;
      padding-bottom: 64px;
    }
    a{
      margin-top: 60px;
    }
  }

  &__title {
    padding-bottom: 19px;
    font-weight: 900;
  }

  &__text {
    width: 100%;
    max-width: 839px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 57px;
    font-size: 24px;
    font-weight: 400;
    color:#ffffff;
  }

  &__box {
    text-align: center;

    &-1 {
      .make__box-icon {
        display: flex;
        align-items: center;

        img {
          position: relative;
          top: 4px;
          left: -1px;
          @include adaptive(767) {
            left: 0;
            top: 0;
          }
        }
      }
    }

    &-2 {
      .make__box-icon {
        img {
          position: relative;
          left: 1px;
          @include adaptive(767) {
            left: 0;
          }
        }
      }
    }

    &-3 {
      .make__box-icon {
        img {
          position: relative;
          left: -3px;
          top: 5px;
          @include adaptive(767) {
            left: 0;
            top: 0;
          }
        }
      }
    }

    &-wrapper {
      position: relative;
      left: 13px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 941px;
      margin-left: auto;
      margin-right: auto;
      @include adaptive(1023) {
        left: 0;
      }
      @include adaptive(575) {
        flex-wrap: wrap;
      }

      > div {
        width: 100%;
        max-width: calc((100% - 140px) / 3);
        @include adaptive(767) {
          max-width: calc((100% - 90px) / 3);
        }
        @include adaptive(575) {
          max-width: 100%;
          padding-bottom: 30px;
        }
      }
    }

    &-icon {
      min-height: 202px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-title {
      padding-bottom: 13px;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      @include adaptive(1365) {
        font-size: 18px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }
    }

    &-desc {
      color: $c-brand-30;
      font-size: 20px;
      line-height: 1.5;
      font-weight: 400;
      @include adaptive(1365) {
        font-size: 18px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }
      @include adaptive(575) {
        width: 100%;
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

// SECTION make :: END
