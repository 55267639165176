// TEXT :: START

.c-text {
	font-size: 24px;
	font-weight: 300;
	color: rgba($c-text, 0.7);
	text-align: center;
	line-height: line-height-convert(38,24);
  letter-spacing: 0.2px;
  @include adaptive(1365) {
    font-size: 22px;
  }
  @include adaptive(1023) {
    font-size: 20px;
  }
  @include adaptive(767) {
    font-size: 18px;
  }
  @include adaptive(575) {
    font-size: 16px;
  }
}

// TEXT :: END
