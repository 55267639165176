// SECTION reasons :: START
$w-pathImage-1: calc(1476px / 3);
$h-pathImage-1: calc(1545px / 3);

.reasons {
  &__bg {
    @include sectionBGOpt('320px', '../img/img-reasons-bg.svg');
    top: -150px;
    left: -50px;
    width: calc(100% + 100px);
    height: calc(100% + 470px);
  }

  &__path {
    &-img {
      &-1 {
        top: -52px;
        left: 50px;
        width: $w-pathImage-1;
        height: $h-pathImage-1;
        filter: blur($filter-blur-image-12);
        @include adaptive(1279) {
          top: -22px;
          max-width: calc(#{$w-pathImage-1} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-1} / 1.5);
        }
        @include adaptive(767) {
          max-width: calc(#{$w-pathImage-1} / 2);
          max-height: calc(#{$h-pathImage-1} / 2);
        }
        @include adaptive(575) {
          left: 15px;
          max-width: calc(#{$w-pathImage-1} / 2.5);
          max-height: calc(#{$h-pathImage-1} / 2.5);
        }
        @include adaptive(374) {
          max-width: calc(#{$w-pathImage-1} / 2.75);
          max-height: calc(#{$h-pathImage-1} / 2.75);
        }
      }
    }
  }

  &__wrapper {
    overflow: hidden;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 452px 15px 204px;
    @include adaptive(767) {
      padding-top: 302px;
      padding-bottom: 104px;
    }
    @include adaptive(575) {
      padding-top: 252px;
    }
  }

  &__title {
    padding-bottom: 7px;
    font-weight: 900;
  }

  &__text {
    width: 100%;
    max-width: 745px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 133px;
    color:#fff;
    font-size: 24px;
    font-weight: 400;
    @include adaptive(767) {
      padding-bottom: 83px;
    }
    @include adaptive(575) {
      padding-bottom: 40px;
    }
  }

  &__carousel {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    &-btn {
      z-index: 10;
      position: absolute;
      top: calc(50% + 36px);
      transform: translateY(-50%);
      width: 103px;
      height: 116px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @include adaptive(1023) {
        max-width: calc(113px / 2);
      }
      @include adaptive(575) {
        display: none;
      }

      &.swiper-button-disabled {
        opacity: 0.12;
        cursor: not-allowed;
      }

      &--prev {
        left: -15px;
        background-image: url('../img/prev@3x.png');
      }

      &--next {
        right: -15px;
        background-image: url('../img/next@3x.png');
      }
    }

    &-wrapper {
      position: relative;

      .swiper {
        &-container {
          width: 100%;
          max-width: 932px;
          margin-left: auto;
          margin-right: auto;
          @include adaptive(575) {
            padding-bottom: 30px;
          }
        }

        &-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          padding-top: 50px;
        }

        &-slide {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0 80px 70px;
          @include adaptive(575) {
            padding: 0 30px 50px;
          }

          &:before,
          &:after {
            content: '';
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }

          &:before {
            background-image: url('../img/img-reasons-bg-2.svg');
            @include adaptive(575) {
              background-image: url('../img/img-reasons-bg-2--mobile.svg');
            }
            @include adaptive(424) {
              background-image: url('../img/img-reasons-bg-2--extra-mobile.svg');
            }
          }

          &:after {
            top: 20px;
            background-image: url('../img/img-reasons-bg-1.svg');
            @include adaptive(575) {
              background-image: url('../img/img-reasons-bg-1--mobile.svg');
            }
            @include adaptive(424) {
              background-image: url('../img/img-reasons-bg-1--extra-mobile.svg');
            }
          }

          &-cover {
            z-index: 2;
            position: relative;
            top: -40px;
            @include adaptive(1023) {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 100%;
              top: 0;
            }
          }
        }

        &-pagination {
          display: none;
          align-items: center;
          justify-content: center;
          bottom: 0;
          @include adaptive(575) {
            display: flex;
          }

          &-bullet {
            opacity: 1;
            width: 15px;
            height: 15px;
            background-color: rgba(#fff, 0.35);
            margin-left: 3px;
            margin-right: 3px;
            @include transition(width, border-radius, background-color);

            &-active {
              width: 30px;
              border-radius: 7px;
              background-color: #fff;
              @include transition(width, border-radius, background-color);
            }
          }
        }
      }
    }

    &-num {
      display: flex;
      justify-content: center;
      padding-bottom: 16px;
      font-weight: 900;
      font-size: 200px;
      color: #fff;
      text-shadow: 0px 14px 54px rgba(0, 0, 0, 0.25);

      @include adaptive(767) {
        padding-bottom: 10px;
      }

      &--1 {
      }

      &--2 {
      }

      &--3 {
      }

      &--4 {
      }

      &--5 {
      }

      img {
        position: relative;
        left: -3px;
        @include adaptive(1023) {
          left: 0;
          max-height: 130px;
        }
        @include adaptive(767) {
          max-height: 100px;
        }
        @include adaptive(575) {
          max-height: 85px;
        }
        @include adaptive(374) {
          max-height: 65px;
        }
      }
    }

    &-title,
    &-desc {
      text-align: center;
    }

    &-title {
      padding-bottom: 21px;
      font-size: 32px;
      color: #fff;
      font-weight: 900;
      text-transform: uppercase;
      @include adaptive(1365) {
        font-size: 30px;
      }
      @include adaptive(1023) {
        padding-bottom: 15px;
        font-size: 24px;
      }
      @include adaptive(767) {
        font-size: 20px;
      }
      @include adaptive(575) {
        padding-bottom: 10px;
        font-size: 17px;
      }
      @include adaptive(374) {
        padding-bottom: 5px;
        font-size: 15px;
      }
    }

    &-desc {
      position: relative;
      left: 6px;
      font-weight: 400;
      font-size: 24px;
      color: #fff;
      line-height: 1.6;
      letter-spacing: 0.1px;
      @include adaptive(1365) {
        font-size: 22px;
      }
      @include adaptive(1023) {
        left: 0;
        font-size: 19px;
      }
      @include adaptive(767) {
        font-size: 17px;
      }
      @include adaptive(575) {
        font-size: 14px;
      }
      @include adaptive(374) {
        font-size: 13px;
      }
    }
  }
}

// SECTION reasons :: END
