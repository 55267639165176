// HEADER :: START

.header {
  height: 70px;
  width: 100%;
  background-image: none;
  background: #1f144d8c;
  backdrop-filter: blur(8px);
  z-index: 9;
  transition: all 0.1s ease-in-out;
  position: fixed;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  &__wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    >a {
      display: flex;
    }
  }
  img {
    &.logo {
      margin-left: 25px;
    }
    &.icon-contact {
      vertical-align: middle;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    margin-right: 25px;
    a {
      font-family: Nunito;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #FFFFFF;
      margin-left: 27px;
      &:hover {
        color: #ffffffe6;
      }
    }
  }
}


// HEADER :: END
