// SECTION trusted :: START

$w-pathImage-1: calc(1494px / 3);
$h-pathImage-1: calc(1393px / 3);

$w-pathImage-2: calc(628px / 3);
$h-pathImage-2: calc(820px / 3);

.trusted {
  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 320px);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -50px;
      width: calc(100% + 100px);
      height: 350px;
      background-image: url("../img/img-team-clipPath.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: cover;
      @include respondCustom("(min-width: 1921px)") {
        top: -50px;
        left: -200px;
        width: calc(100% + 400px);
        height: 400px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 300px;
      left: 0;
      width: 100%;
      height: calc(100% - 300px);
      background-color: $c-brand-170;
    }
  }

  &__grid {
    position: relative;
    left: 11px;
    width: 100%;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 164px;
    @include adaptive(1279) {
      left: 0;
    }
    @include adaptive(767) {
      padding-bottom: 124px;
    }
    @include adaptive(575) {
      padding-bottom: 84px;
    }

    &-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% + 10px);
      margin-left: -10px;
      @include adaptive(1023) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include adaptive(575) {
        flex-direction: column;
      }
      > div {
        width: 100%;
        max-width: calc(100% / 4);
        height: 150px;
        padding-left: 10px;
        padding-bottom: 10px;
        @include adaptive(1023) {
          max-width: calc(100% / 3);
        }
        @include adaptive(767) {
          max-width: calc(100% / 2);
        }
        @include adaptive(575) {
          max-width: 290px;
          margin-left: auto;
          margin-right: auto;
        } 
      }
      
    }

    &-item {
      border-radius: 15px;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 20px;
      img {
        width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
      &-1 {
        background-color: #fff;
      }
      &-2 {
        background-color: #C92F2E;
      }
      &-3 {
        background-color: #7536C6;
        padding: 30px;
      }
      &-4 {
        background-color: #06F;
        padding: 40px;
      }
      &-5 {
        background-color: #00B75D;
      }
      &-6 {
        background-color: #FEF;
        padding: 30px;
      }
      &-7 {
        background-color: #7D0505;
        padding: 30px;
      }
      &-8 {
        background-color: #005983;
        padding: 10px;
      }
      &-9 {
        background-color: #FF005B;
        padding: 35px;
      }
      &-10 {
        background-color: #FFD645;
        padding: 35px;
      }
      &-11 {
        background-color: #FFF;
      }
      &-12 {
        background-color: #0067DF;
      }
      &-13 {
        background-color: #FFF;
        padding: 10px;
      }
      &-14 {
        background-color: #445CC8;
      }
      &-15 {
        background-color: #0082A8;
        padding: 30px;
      }
      &-16 {
        background-color: #FFF;
      }
      &-17 {
        background-color: #FFF;
        padding: 30px;
      }
      &-18 {
        background-color: #27AFE5;
      }
      &-19 {
        background-color: #FFF;
        padding: 30px;
      }
      &-20 {
        background-color: #4380ED;
        padding: 25px;
      }


    }
  }

  &__path {
    &-img {
      &-1 {
        top: -108px;
        left: 0;
        width: $w-pathImage-1;
        height: $h-pathImage-1;
        filter: blur($filter-blur-image-8);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-1} / 1.25);
        }
        @include adaptive(1023) {
          max-width: calc(#{$w-pathImage-1} / 1.5);
        }
        @include adaptive(767) {
          top: -38px;
          max-width: calc(#{$w-pathImage-1} / 2);
          max-height: calc(#{$h-pathImage-1} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-1} / 2.5);
          max-height: calc(#{$h-pathImage-1} / 2.5);
        }
      }

      &-2 {
        top: 144px;
        right: 78px;
        width: $w-pathImage-2;
        height: $h-pathImage-2;
        filter: blur($filter-blur-image-9);
        @include adaptive(1279) {
          max-width: calc(#{$w-pathImage-2} / 1.25);
        }
        @include adaptive(1023) {
          right: 18px;
          max-width: calc(#{$w-pathImage-2} / 1.5);
        }
        @include adaptive(767) {
          top: 104px;
          right: 0;
          max-width: calc(#{$w-pathImage-2} / 2);
          max-height: calc(#{$h-pathImage-2} / 2);
        }
        @include adaptive(575) {
          max-width: calc(#{$w-pathImage-2} / 2.5);
          max-height: calc(#{$h-pathImage-2} / 2.5);
        }
      }
    }
  }

  &__wrapper {
    padding-top: 308px;
    padding-bottom: 35px;
    @include adaptive(767) {
      padding-top: 258px;
      padding-bottom: 17px;
    }
  }

  &__title {
    padding-bottom: 36px;
    font-weight: 900;
    text-transform: none;
  }

}

// SECTION trusted :: END
