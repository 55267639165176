// TITLE :: START

.c-title {
	font-size: 100px;
	color: $c-title;
	letter-spacing: 0;
	text-align: center;
  text-transform: uppercase;
	line-height: line-height-convert(108,100);
  @include adaptive(1365) {
    font-size: 90px;
    line-height: line-height-convert(98,90);
  }
  @include adaptive(1023) {
    font-size: 75px;
    line-height: line-height-convert(90,75);
  }
  @include adaptive(767) {
    font-size: 60px;
  }
  @include adaptive(575) {
    font-size: 45px;
  }
  @include adaptive(374) {
    font-size: 40px;
  }
}

// TITLE :: END
