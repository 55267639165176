@import "function";

////
/// @group  Variable
////

///
/// @name   object
///
$object: (
  colors : (
    white                 : #fff,
    black                 : #000,

    primary               : #4285f4,

    success               : #00c851,
    info                  : #33b5e5,
    warning               : #ff8800,
    danger                : #cc0000
  ),
);

///
/// MAIN PALLET
/// ===============
$c-white                  : return-value(colors, white);
$c-black                  : return-value(colors, black);

$c-primary                : return-value(colors, primary);

$c-success                : return-value(colors, success);
$c-info                   : return-value(colors, info);
$c-warning                : return-value(colors, warning);
$c-danger                 : return-value(colors, danger);

///
/// ADDITIONAL PALLET
/// ===============

// GLOBAL
// ========================
$c-title : #fff;
$c-text : #c9d6ff;

// Filter blur for path object from top-left to bottom (... add in pixels)
$filter-blur-image-1 : 0;
$filter-blur-image-2 : 1px;
$filter-blur-image-3 : 0;
$filter-blur-image-4 : 0;
$filter-blur-image-5 : 11px;
$filter-blur-image-6 : 0;
$filter-blur-image-7 : 9px;
$filter-blur-image-8 : 10px;
$filter-blur-image-9 : 0;
$filter-blur-image-10 : 0;
$filter-blur-image-11 : 9px;
$filter-blur-image-12 : 7px;
$filter-blur-image-13 : 17px;
$filter-blur-image-14 : 0;

// NEW COLORS
$c-brand-30:#D2C6FF;
$c-brand-80:#8668FF;
$c-brand-100:#6842FF;
$c-brand-110:#5E3BE6;
$c-brand-120:#5335CC;
$c-brand-130:#492EB3;
$c-brand-140:#3E2899;
$c-brand-150:#342180;
$c-brand-160:#2A1A66;
$c-brand-170:#1F144D;

$c-white-100:#F9FAFF;
$c-white-50:#AAADBE;


